import React from 'react';
import { Link } from 'react-router';

class Home extends React.Component {
render() {
      return (
      <section className="home">
        <div className="home__body">
          <div style={{
            width: '100%',
            height: '0',
            paddingBottom: '56.25%',
            position: 'relative'
          }}>
            <iframe src="https://www.youtube.com/embed/NwohCFOHDRU" style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%'
            }} frameBorder="0" allowFullScreen></iframe>
          </div>
          <div className="home-under-construction">
            <span>Under construction</span>
            <h1>Our homepage is currently being updated, below is 2021 version</h1>
          </div>
          <iframe id="hhome" src={`https://img.altocity.io/static/hy/home/home.html`}  scrolling="yes"></iframe>
        </div>
      </section>
      );
    }
}
export default Home;
