import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const AuctionPanel = ({ account }) => {
  const [auctionData, setAuctionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/api/auction-info/`)
      .then(response => {
        setAuctionData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.toString());
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Link to="/wallet/auction">
      <div className="auction-panel">
        <h2 className="panelHeader">Next city on Altoverse auction</h2>
        {auctionData.this_month_auction &&
          <>
            <p>This Month's Leader: <span className="value">{auctionData.this_month_auction.winner}</span></p>
            <p>Current bid: <span className="value">{auctionData.this_month_auction.current_bid}</span></p>
          </>
        }
        {auctionData.last_month_auction &&
          <>
            <p>Last Month's Winner: <span className="value">{auctionData.last_month_auction.winner}</span></p>
            <p>Winning bid: <span className="value">{auctionData.last_month_auction.winning_bid}</span></p>
          </>
        }
      </div>
    </Link>
  );
};

export default AuctionPanel;
