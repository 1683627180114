import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

//const Web3Utils = require('web3-utils');

class OwnedItemsGallery extends Component {
  state = {
    items: [],
    count: 20,
    hasMore: true,
    search: this.props.search,
    loading: false,
    ownedTokenIndex: 0
  };

  debouncedSearch = _.debounce(search => {
    this.setState({
      search,
      items: [],
      ownedTokenIndex: 0,
      hasMore: true,
      tokens: []
    }, () => this.fetchImages());
  }, 500);

  getLoadingPromises = function *(tokensId) {
    for (const tokenId of tokensId) {
      yield axios.get(`${process.env.REACT_APP_API}/api/get_item/${tokenId}`)
    }
  }

  componentDidMount() {
    this.debouncedSearch(this.state.search);
    this.fetchImages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.debouncedSearch(this.props.search);
    }
  }

  fetchImages = async () => {
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    const { count, search, ownedTokenIndex } = this.state;
    const { account } = this.props;

    axios.get(`${process.env.REACT_APP_API}/api/get_owner_items/?offset=${ownedTokenIndex}&search=${encodeURIComponent(search || '')}&owner=${account}`)
      .then(res => {
        const newItems = res.data;
        const hasMore = newItems.length === count;

        this.setState(prevState => ({
          items: [...prevState.items, ...newItems],
          loading: false,
          ownedTokenIndex: prevState.ownedTokenIndex + count,
          hasMore,
        }));
      })
      .catch(err => {
        console.error('Error during fetchImages:', err);
        this.setState({ loading: false });
      });
  }


  render() {
    const { items, hasMore } = this.state;

    return (
      <InfiniteScroll
        dataLength={items.length}
        next={this.fetchImages}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }>
        {items.map(item =>
          <NavLink to={`wallet/${item.bchain_token_id}`} className="wallet-have-images__item wallet-have__image" key={item.bchain_token_id}>
            <div className="wallet-have__pic">
              <img src={item.image} alt="" />
            </div>
            <div className="wallet-have__name">{item.name}</div>
          </NavLink>
        )}
      </InfiniteScroll>
    );
  }
}

export default OwnedItemsGallery;
