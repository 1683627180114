import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {ItemsBalance} from "../components";
import Web3 from 'web3';
import ABI from '../abis/contractABI';

function AuctionPage() {
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [altocoins, setAltocoins] = useState(0);
  const [projecteddue, setProjectedDue] = useState(0);
  const [lastWinners, setLastWinners] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [bidPanelOpen, setBidPanelOpen] = useState(false);
  const [currentBid, setCurrentBid] = useState(0);
  const [newBid, setNewBid] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [ walletLoaded, setWalletLoaded ] = useState(false)
  const [currentCity, setCurrentCity] = useState(null);

  useEffect(() => {
    const loadBlockchainData = async () => {
      if (window.ethereum) {
        const web3local = new Web3(window.ethereum);
        setWeb3(web3local);

        const accounts = await web3local.eth.getAccounts();
        setAccount(accounts[0]);

        const tokenContractLocal = new web3local.eth.Contract(ABI, process.env.REACT_APP_TOKEN_ADDRESS);
        setTokenContract(tokenContractLocal);
      } else if (window.web3) {
        const web3local = new Web3(window.web3.currentProvider);
        setWeb3(web3local);

        const accounts = await web3local.eth.getAccounts();
        setAccount(accounts[0]);

        const tokenContractLocal = new web3local.eth.Contract(ABI, process.env.REACT_APP_TOKEN_ADDRESS);
        setTokenContract(tokenContractLocal);
      }
    };

    loadBlockchainData();
  }, []);

  const getWallet = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}/api/get_or_create_wallet/?wallet=${account.toLowerCase()}`);
    setAltocoins(res.data.altocoins);
    setProjectedDue(res.data.projecteddue);
    setWalletLoaded(true);
  }

  const getTopCities = async () => {
    await axios.get(`${process.env.REACT_APP_API}/api/auction_current_top_cities/`, {
      params: {
        account: account
      }
    })
      .then(res => setTopCities(res.data))
      .catch(err => console.log(err));
  }

  useEffect(() => {
    if (account) {
      getWallet();
      getTopCities();
      axios.get(`${process.env.REACT_APP_API}/api/auction_last_winners/`)
        .then(res => setLastWinners(res.data))
        .catch(err => console.log(err));
    }
  }, [account]);

  useEffect(() => {
    if(currentCity) {
      setCurrentBid(currentCity.user_bet);
    }
  }, [account, currentCity]);

  if (!account || !tokenContract) {
    return <div>Loading...</div>;
  }


  const getMonthFromDate = (dateString) => {
    if (dateString === undefined || !dateString) {
      return new Date().toLocaleDateString('en-US', { month: 'long' });
    }
    return new Date(dateString).toLocaleDateString('en-US', { month: 'long' });
  };

  const handleRowClick = city => {
    setCurrentBid(city.user_bet);
    setUserBalance(altocoins);
    setBidPanelOpen(true);
    setCurrentCity(city);
  };

  const handleBidChange = e => {
    setNewBid(e.target.value);
  };

  const handleBidInputFocus = () => {
    if(newBid === 0) {
      setNewBid("");
    }
  }

  const handleCancelBid = () => {
    setBidPanelOpen(false);
    setNewBid(0);
  };

  const handlePlaceBid = () => {
    if (newBid > userBalance || newBid < 1) {
      alert("Your bid can't exceed your balance");
    } else {
      axios.post(`${process.env.REACT_APP_API}/api/place_bid/`, {
        wallet: account,
        bet: newBid,
        city: currentCity.city,
        country: currentCity.country,
        state: currentCity.state
      })
        .then(response => {
          if(response.data.status === 'success'){
            alert(response.data.message);
            setBidPanelOpen(false);
            setNewBid(0);
            // Call getWallet and getTopCities after a successful bid
            getWallet();
            getTopCities();
            // Update currentCity
            setCurrentCity({
              ...currentCity,
              user_bet: parseFloat(currentCity.user_bet) + parseFloat(newBid),
            });
          }
          else {
            alert(response.data.message);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  return (
    <section className="auction">
      <div className="auction__body">
        <div className="auction__title">
          <span>Auction</span>
        </div>

        <ItemsBalance tokenContract={tokenContract} account={account} altocoins={altocoins} />

        <h2>Current Leaders</h2>
        <table>
          <thead>
          <tr>
            <th>City</th>
            <th>Country</th>
            <th>State</th>
            <th>Total Bid</th>
            <th>Your Bid</th>
          </tr>
          </thead>
          <tbody>
          {topCities.map(city => (
            <tr className="current" key={city.city} onClick={() => handleRowClick(city)}>
              <td>{city.city}</td>
              <td>{city.country}</td>
              <td>{city.state}</td>
              <td>{city.total_bet}</td>
              <td>{city.user_bet}</td>
            </tr>
          ))}
          </tbody>
        </table>

        <h2>Last Winners</h2>
        <table>
          <thead>
          <tr>
            <th>Winner</th>
            <th>Winning Bid</th>
            <th>Month</th>
          </tr>
          </thead>
          <tbody>
          {lastWinners.map(winner => (
            <tr key={winner.auction_id}>
              <td>{winner.winner}</td>
              <td>{winner.winning_bid}</td>
              <td>{getMonthFromDate(winner.end_date)}</td>
            </tr>
          ))}
          </tbody>
        </table>

        {bidPanelOpen && currentCity && (
          <div className="auction_bid">
            <h2>Place Your Bid</h2>
            <p>City: {currentCity.city}</p>
            <p>Country: {currentCity.country}</p>
            <p>Total Bid: {currentCity.total_bet}</p>
            <p>Current Bid: {currentBid}</p>
            <p>Your Balance: {userBalance}</p>
            <input type="number" min="0" max={userBalance} value={newBid} onChange={handleBidChange} onFocus={handleBidInputFocus}/>
            <div className="buttons-container">
              <button className="cancel-btn" onClick={handleCancelBid}>Cancel</button>
              <button className="place-bid-btn" onClick={handlePlaceBid}>Place Bid</button>
            </div>
            <p className="disclaimer">Disclaimer: You cannot cancel your bids; if your bid does not play, you will get a full refund.</p>
          </div>
        )}
      </div>
    </section>
  );
}


export default AuctionPage;
