import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Success({ type }) {
  const [user, setUser] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userid = new URLSearchParams(window.location.search).get('userid');

    if(userid && type === 'success') {
      axios.post(`${process.env.REACT_APP_API}/api/altoverse_user_by_userid/`, { userid })
        .then(response => {
          const data = response.data;
          if(data.result && Object.keys(data.result).length > 0) {
            setUser(data.result);
            setWalletBalance(data.walletBalance);
          } else {
            setUser(null);
          }
          setLoading(false);
        })
        .catch(error => {
          //console.error('Error:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [type]);


  if(loading) {
    return <div>Loading...</div>;
  }

  if(type === 'cancel' || !user) {
    return <section className="cancel">
      <div className="cancel__body">
        <div className="cancel__title">
          <span>Something went wrong</span><div>Please try again</div>
        </div>
      </div>
    </section>
  }

  return (
    <section className="success">
      <div className="success__body">
        <div className="success__title">
          <span>Success</span>
          <div className="success__transfer-panel">
            <img
              className="alto-pfp"
              src={user && user.pfpimage ? user.pfpimage.medium : ''}
              alt="Altoverse PFP"
            />
            <div className="success__transfer-userinfo">
              <h1>{user && user.name ? user.name : 'Unknown User'}</h1>
              <h2>Total Altoverse coins: <div>{user?.coins ?? 0}</div></h2>
              <h2>Total wallet coins: <div>{walletBalance}</div></h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Success;
