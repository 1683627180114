import React, { useState, useEffect } from "react";

import { ItemsBalance, OwnedItemsGallery, AuctionPanel } from "../components";

import ABI from '../abis/contractABI'
import Web3 from 'web3';

export function Wallet() {
  const [ account, setAccount ] = useState();
  const [ web3, setWeb3 ] = useState();
  const [ network, setNetwork] = useState();
  const [ search, setSearch ] = useState(''); // search string state

  const onSearchTextChange = (event) => {
    setSearch(event.target.value)
  };

  const isCorrectNetwork = (currentNetwork) => {
    return currentNetwork === "0x1" || currentNetwork === "0x4";
  }

  const onConnectMetamaskClick = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(res[0])
        setNetwork(window.ethereum.chainId)

        if (!isCorrectNetwork(window.ethereum.chainId)) {
          alert('Only Mainnet or Rinkeby networks')
        }
      } catch (err) {
        if (err.code == 4001) {
          alert("You need to get Metamask to continue");
        } else if (err.code == -32002) {
          alert("Check metamask connection requests");
        } else {
          console.log(err);
        }
      }
    } else {
      window.open("https://metamask.io/", "_blank").focus();
    }
  };

  useEffect(() => {
    const initWeb3 = async () => {
      let web3local
      if (window.ethereum) {
        web3local = new Web3(window.ethereum)
        setWeb3(web3local);
        setNetwork(window.ethereum.chainId)
      } else if (window.web3) {
        web3local = new Web3(window.web3.currentProvider)
        setWeb3(web3local);
        setNetwork(window.web3.currentProvider.chainId)
      }

      if (web3local) {
        window.ethereum.on('disconnect', () => { setAccount(null) })
        window.ethereum.on('accountsChanged', (accs) => { accs.length > 0 ? setAccount(accs[0]) : setAccount(null) })
        window.ethereum.on('chainChanged', (newNetwork) => {
          setNetwork(newNetwork)
        })
        const accounts = await web3local.eth.getAccounts();
        setAccount(accounts[0])
      }
    }

    initWeb3();
  }, [])

  function getMetamaskRequest() {
    return (
      <section className="mint-dc">
        <div className="mint-dc__body">
          <div className="mint-dc__title">Connect your wallet</div>
          <div className="mint-dc__image">
            <img src={`${process.env.PUBLIC_URL}/img/fox-big.svg`} alt="" />
          </div>
          <div className="mint-dc__button button">
            <a
              onClick={onConnectMetamaskClick}
              className="mint-dc__link button__link"
            >
              CONNECT
            </a>
          </div>
        </div>
      </section>
    );
  }

  function getPageContent() {
    if (!web3) {
      return null; // Or return a loading indicator, error message, etc.
    }
    const tokenContract = new web3.eth.Contract(ABI, process.env.REACT_APP_TOKEN_ADDRESS);

    return (
      <section className="wallet-have">
        <div className="wallet-have__body">

          <div className="wallet-have__title">
            <span>Your cities</span>
            <div className="gallery-search__column">
              <input type="text" placeholder="Search for cities" onChange={onSearchTextChange} value={search} />
            </div>
          </div>

          <div className="wallet-have__list">
            <div className="wallet-have__item">
              <ItemsBalance tokenContract={tokenContract} account={account} />
            </div>
          </div>

          <div className="auction_panel-wrapper">
            <AuctionPanel />
          </div>

          <div id="ownedItemsGallery" className="wallet-have__images wallet-have-images">
            <OwnedItemsGallery token={tokenContract} account={account} search={search} />
          </div>
        </div>
      </section>
    );
  }

  return <div>{account && isCorrectNetwork(window.ethereum.chainId) ? getPageContent() : getMetamaskRequest()}</div>;
}

export default Wallet;
