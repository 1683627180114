import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'

export function ItemsBalance(props) {
  const { tokenContract, account, altocoins } = props
  const [ balance, setBalance ] = useState()
  const [ balanceLoaded, setBalanceLoaded ] = useState(false)
  const [ projectedDue, setProjectedDue ] = useState()
  const [ altoCoins, setAltoCoins ] = useState()
  const [ walletLoaded, setWalletLoaded ] = useState(false)

  useEffect(() => {
    const getItems = async () => {
      const balance = await tokenContract.methods.balanceOf(account).call()
      setBalance(balance.toString())
      setBalanceLoaded(true)
    }
    getItems()
  }, [tokenContract, account])

  useEffect(() => {
    const getWallet = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/get_or_create_wallet/?wallet=${account.toLowerCase()}`);
      setProjectedDue(res.data.projecteddue)
      setAltoCoins(res.data.altocoins)
      setWalletLoaded(true)
    }
    getWallet()
  }, [account])

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash === "#ownedItemsGallery") {
        const element = document.getElementById('ownedItemsGallery');
        if (element) element.scrollIntoView({behavior: "smooth"});
      }
    };

    // scroll to the element if the hash exists when the component mounts
    handleHashChange();

    // subscribe to hash changes
    window.addEventListener('hashchange', handleHashChange, false);

    // cleanup
    return () => {
      window.removeEventListener('hashchange', handleHashChange, false);
    };
  }, []);

  return (
    <>
      <div className="panel">
        <div className="item-row">
          <div className="wallet-detail">
            <div className="item-price">Your NFTs</div>
            <div className="item-amount">
              { balanceLoaded ? <a href="/wallet#ownedItemsGallery">{balance}</a> : "Loading..."}
            </div>
          </div>
          <div className="wallet-detail">
            <div className="balance">Your Alto Coins</div>
            <div className="item-amount">
              { walletLoaded ? <Link to={`/wallet/walletHistory`}>{altoCoins}</Link> : altocoins ? altocoins : "Loading..."}
            </div>
          </div>
          <div className="wallet-detail">
            <div className="projecteddue">Projected Due</div>
            <div className="item-amount-projected">
              { walletLoaded ? `${projectedDue}` : "Loading..."}
            </div>
          </div>
        </div>
      </div>
      {
        balance == 0
          ?
          <div className="wallet-c__subtitle">
            On this page you will be able to view your cities, vote for next city on Altoverse, swap your cities, check your bonus payments and more.<br/>
          </div>
          :
          <></>
      }
    </>
  )
}

export default ItemsBalance;
