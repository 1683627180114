import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';

// function getLibrary(provider) {
//   const library = new Web3Provider(provider)
//   library.pollingInterval = 12000
//   return library
// }

ReactDOM.render(
  (
    <App/>
  ),
  document.getElementById('root')
);
