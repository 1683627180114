import React from 'react';
import { NavLink } from 'react-router-dom';

import "../assets/scss/style.scss"

class Header extends React.Component {
    render() {
        return (
            <footer className="footer">
              <div className="container">
                <div className="footer__body">
                  <div className="footer__wrapper">
                    <div className="footer__menu footer-menu">
                      <ul className="footer-menu__list">
                        <li className="footer-menu__list-item">
                          <NavLink to="/disclaimer" className="footer-menu__list-link">
                            Disclaimer
                          </NavLink>
                        </li>
                        <li className="footer-menu__list-item">
                          <NavLink to="/terms" className="footer-menu__list-link">
                            Terms and conditions
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="footer__soc footer-soc">
                      <ul className="footer-soc__list">
                        <li className="footer-soc__list-item">
                          <a href="https://www.instagram.com/art.alto.city/" className="footer-soc__list-link">
                            <img src={`${process.env.PUBLIC_URL}/img/inst.svg`} alt=""/>
                          </a>
                        </li>
                        <li className="footer-soc__list-item">
                          <a href="https://discord.gg/xHYJUF7DHz" className="footer-soc__list-link">
                            <img src={`${process.env.PUBLIC_URL}/img/discor.svg`} alt=""/>
                          </a>
                        </li>
                        <li className="footer-soc__list-item">
                          <a href="https://twitter.com/alto_city" className="footer-soc__list-link">
                            <img src={`${process.env.PUBLIC_URL}/img/twit.svg`} alt=""/>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="footer__footer">
                    <div className="footer__copy">
                      © All rights reserved
                    </div>
                    <div className="footer__made">
                      Made with <img className="flogo" role="img" draggable="false" src={`${process.env.PUBLIC_URL}/icons/altologo.svg`} alt="❤"/> just like all our products.
                    </div>
                  </div>
                </div>
              </div>
		        </footer>
        );
    }
}

export default Header;
