import React from 'react';
import axios from 'axios';

class AssetSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      asset: {},
      last_owner: {},
      new_city: {},
      owner: null,
      swap_date:null
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.fetchData(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (prevProps.id !== id) {
      this.fetchData(id);
    }
  }

  fetchData = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/iget_item/${id}`);
      const { token_metadata, last_owner, new_city } = res.data;

      const date = token_metadata ? new Date(token_metadata.last_swap_date) : new Date ();
      const formattedDate = date.toLocaleDateString() + " " + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      this.setState({ asset: token_metadata, last_owner: last_owner, new_city: new_city, swap_date: formattedDate });
    } catch (error) {
      console.error(error);
    }
  }

  // Check value before redirecting
  isRedirectableValue = (value) => {
    const nonRedirectableValues = [
      1, 2, 3, 4, "Global City2", "Capital City2", "Capital & Global City2", "Other2"
    ];
    return !nonRedirectableValues.includes(value);
  }

  handleAttributeClick = (value) => {
    if (this.isRedirectableValue(value)) {
      const url = new URL(`${window.location.protocol}//${window.location.host}/gallery?search=${encodeURIComponent(value)}`);
      window.location.href = url;
    }
  }

  render() {
    const { asset } = this.state;
    const assetIsEmpty = asset ? Object.keys(asset).length === 0 : true;

    return (
      <section className="asset">
        {!assetIsEmpty && (
          <div className="asset__body">
            <div className="asset__subtitle asset-sub">
              <ul className="asset-sub__list">
                <li className="asset-sub__list-item">
                <span className="asset-sub__list-span">
                  {
                    this.state.asset.owner != undefined
                      ? this.state.asset.bchain_token_id === -1 ?
                        'Burnt' :
                        `Owned by ${this.state.asset.owner}`
                      :
                        `Not minted yet`
                  }

                </span>
                </li>
                <li className="asset-sub__list-item">
                  <a href={this.state.asset.frontend_url} className="asset-sub__list-link">
                    View physical products
                  </a>
                </li>
                {this.state.asset.owner && this.state.asset.bchain_token_id !== -1
                  ?
                  <li className="asset-sub__list-item">
                    <a href={`${process.env.REACT_APP_OPENSEA_COLLECTION}/${this.state.asset.bchain_token_id}`} className="asset-sub__list-link">
                      View this item on OpenSea
                    </a>
                  </li>
                  :
                  this.state.asset.bchain_token_id === -1 ?
                    <li className="asset-sub__list-item">
                      <a href={`${process.env.PUBLIC_URL}/gallery/${this.state.new_city.token_id}`} className="asset-sub__list-link">
                        View the city that swapped it out
                      </a>
                    </li>
                    :
                  <></>
                }
              </ul>
            </div>
            <div className="asset__row">
              <div className="asset__title">
                {this.state.asset.name}
              </div>
              <div className="asset__image">
                <img src={this.state.asset.image} alt="" />
              </div>
              <div className="asset__info asset-info">
                <div className="asset-info__title">
                  {this.state.asset.name}
                </div>
                <div className="asset-info__text">
                  {
                    this.state.asset.bchain_token_id === -1
                      ? <>
                        {'This NFT was swapped out on ' + this.state.swap_date + ' by ' + this.state.last_owner.owner_wallet + ' and got replaced by '}
                        <a href={`${process.env.PUBLIC_URL}/gallery/${this.state.new_city.token_id}`} >
                          {this.state.new_city.name}
                        </a>
                      </>
                      : this.state.asset.description
                  }
                </div>
                <div className="asset-info__property asset-prop">
                  <div className="asset-prop__body">
                    {
                      this.state.asset.attributes ?
                        this.state.asset.attributes.map((attribute, index) => {
                          return (
                            <div className="asset-prop__item" key={index} onClick={
                              () => {
                                  const url = new URL(`${window.location.protocol}//${window.location.host}/gallery?search=${attribute.value}`)
                                  window.location = url
                              }
                            }>
                              <div className="asset-prop__key">
                                {attribute.trait_type}
                              </div>
                              <div className="asset-prop__value">
                                {attribute.value}
                              </div>
                            </div>
                          )
                        })
                        : ""
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default AssetSection;
