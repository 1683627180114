import React, { useState, useEffect, useCallback } from 'react';

const Web3 = require('web3');

export function PublishMessage(props) {
  const { showPopup, showError, itemOwner } = props
  const [ account, setAccount ] = useState()

  const connectMetamaskAccount = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(res[0])
        return true
      } catch (err) {
        if (err.code == 4001) {
          alert("You need connect Metamask to continue");
        }
        if (err.code == -32002) {
          alert("Accept Metamask connection");
        } else {
          console.log(err);
        }
      }
    } else {
      alert("You need install Metamask to continue")
    }
  }

  const initWeb3 = async () => {
    let web3local
    if (window.ethereum) {
      web3local = new Web3(window.ethereum)
    } else if (window.web3) {
      web3local = new Web3(window.web3.currentProvider)
    }

    if (web3local) {
      window.ethereum.on('disconnect', () => { setAccount(null) })
      window.ethereum.on('accountsChanged', (accs) => { accs.length > 0 ? setAccount(accs[0]) : setAccount(null) })
      web3local.eth.getAccounts()
        .then(async (addr) => {
          setAccount(addr[0])
        });

      return web3local
    }
  }

  const publish = async () => {
    console.log('publish clicked')
    const isMetamaskConnected = await connectMetamaskAccount()
    if (isMetamaskConnected) {
      const web3 = await initWeb3()
      if (web3) {
        console.log('selected address: ', window.ethereum.selectedAddress)
        console.log('owner: ', itemOwner)
        if (window.ethereum.selectedAddress === itemOwner) {
          showPopup()
        } else {
          showError()
        }
      }
    }
  }

  return (
    <div className="asset-info__pub-message" onClick={publish} key={account}>
      Publish message
    </div>
  )
}

export default PublishMessage;