import React, { useState, useEffect } from 'react';

import ABI from '../abis/contractABI'
const Web3 = require('web3');

export function CurrentTokenPrice(props) {
  const { web3 } = props
  const [ currentPrice, setCurrentPrice ] = useState();
  const [ priceLoaded, setPriceLoaded ] = React.useState(false);

  const tokenContract = new web3.eth.Contract(ABI, process.env.REACT_APP_TOKEN_ADDRESS);

  useEffect(() => {
    const getPrice = async () => {
      const price = await tokenContract.methods.getPrice().call()
      //setCurrentPrice(price / (10**18))
      setCurrentPrice(90000000000000000 / (10**18))
      setPriceLoaded(true)
    }
    getPrice()
  })

  return (
    <div className="mint-c__item">
      <div className="mint-c__item-row">
        <div className="mint-c__item-price">
          Price
        </div>
        <div className="mint-c__item-amount">
          { priceLoaded ? `${currentPrice} ETH` : "Loading..."}
        </div>
      </div>
    </div>
  )
}

export default CurrentTokenPrice;
