import React from 'react';
import { Link } from 'react-router';
import axios from "axios";

class Faq extends React.Component {
  async onClc() {
    for (var tokenId = 1; tokenId <= 4300; tokenId++) {
      const contractAddress = "0x708da3646cfa5ab8835672ecb5ae2f2494b7fa81";
      const url = `https://api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}/?force_update=true`;
      const res = await axios.get(url);
      console.log("Done with ${tokenId}");
    }
  }

  render() {
    return (
      <section className="home">
        <div className="home__body">
          <h1 className="home__title">
            404
				</h1>
          <div className="home__text">
            Nothing here<a onClick={this.onClc}>.</a>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
