import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

import { CurrentTokenPrice } from "../components";

import ABI from "../abis/contractABI";
import Web3 from 'web3';

import axios from "axios";

function Mint() {
  const [ mintedTokens, setMintedTokens ] = useState([]);
  const [ mintedToken, setMintedToken ] = useState();
  const [ isMinting, setMinting] = useState(false)
  const [ isConfirmed, setConfirmed] = useState(false)
  const [ account, setAccount ] = useState();
  const [ web3, setWeb3 ] = useState();
  const [ network, setNetwork] = useState();
  const [ key, setKey ] = useState(0);
  const [quant, setQuantity] = useState(1);
  const [ saleStatus, setSaleStatus ] = useState(true);
  const [ localSaleStatus, setLocalSaleStatus ] = useState(false);

  const isCorrectNetwork = (currentNetwork) => {
    return currentNetwork === "0x1" || currentNetwork === "0x4";
  }

  const useSlider = (min, max, defaultState, label, id) => {
    const handleChange = e => {
      setQuantity(e.target.value);
    }

    const props = {
      type: 'range',
      id,
      min,
      max,
      step: 1,
      value: quant,
      onChange: handleChange
    }
    return props
  }
  const sliderProps = useSlider(1, 20, 1, "Threshold", 'threshold')

  useEffect(() => {
    let web3local;
    if (window.ethereum) {
      web3local = new Web3(window.ethereum);
      setWeb3(web3local);
      setNetwork(window.ethereum.chainId);
    } else if (window.web3) {
      web3local = new Web3(window.web3.currentProvider);
      setWeb3(web3local);
      setNetwork(window.web3.currentProvider.chainId);
    }

    if (web3local) {
      window.ethereum.on('disconnect', () => { setAccount(null) })
      window.ethereum.on('accountsChanged', (accs) => {
        accs.length > 0 ? setAccount(accs[0]) : setAccount(null)
      })
      window.ethereum.on('chainChanged', (newNetwork) => {
        setNetwork(newNetwork)
      })

      web3local.eth.getAccounts()
        .then(async (addr) => {
          setAccount(addr[0])
        });
    }
    const getSaleStatus = async () => {

      const tokenContr = new web3local.eth.Contract(ABI, process.env.REACT_APP_TOKEN_ADDRESS);
      const stu = await tokenContr.methods.saleIsActive().call();
      //const totalSupply = await tokenContract.methods.maxSupply().call()
      const totSup =  BigInt(7952);
      const itCount = await tokenContr.methods.totalSupply().call();
      const rem = totSup - itCount
      rem <= BigInt(0)  ? setSaleStatus(false) : setSaleStatus(stu);
      //setSaleStatus(stu)
    }
    getSaleStatus()
  }, [])

  const onConnectMetamaskClick = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(res[0])
        setNetwork(window.ethereum.chainId)

        if (!isCorrectNetwork(window.ethereum.chainId)) {
          alert('Please checkout to Mainnet or Rinkeby network')
        }
      } catch (err) {
        if (err.code == 4001) {
          alert("You need to activate Metamask to continue");
        } else if (err.code == -32002) {
          alert("Check metamask connection requests");
        }
        else {
          console.log(err);
        }
      }
    } else {
      window.open("https://metamask.io/", "_blank").focus();
    }
  };

  async function showMintedToken(tokenId) {
    const res = await axios.get(
      `${process.env.REACT_APP_API}/api/get_item/${tokenId}`
    );
    setMintedToken(res.data);
    togglePopup();
  }
  async function showMintedTokens(tokenIds) {
    const fetchPosts = async (number) => {
      const res = await axios.get(
        `${process.env.REACT_APP_API}/api/get_item/${number}`
      );
      return res.data;
    }
    const post = [];
    for (const tokenId of tokenIds) {
      post.push(await fetchPosts(tokenId));
    }
    setMintedTokens(post);
    togglePopups();
  }

  function togglePopup() {
    const modal = document.getElementById("modal");
    modal.style.display = modal.style.display === "block" ? "none" : "block";
  }

  function togglePopups() {
    const modals = document.getElementById("modals");
    modals.style.display = modals.style.display === "block" ? "none" : "block";
  }

  async function onClick() {
    setMinting(true)
    const tokenContract = new web3.eth.Contract(ABI, process.env.REACT_APP_TOKEN_ADDRESS);
    const price = 90000000000000000;
    //await tokenContract.methods.getPrice().call();
    console.log('Before gas price calculation');
    const currentGasPrice = await web3.eth.getGasPrice();
    console.log('Current gas price:', currentGasPrice);
    const multiplier = BigInt(103);
    const divisor = BigInt(100);
    const sendTransactionData = {
      gasPrice: (currentGasPrice * multiplier) / divisor,
      from: account,
      value: price*quant
    };
    console.log('After gas price calculation');
    let res;
    try {
      //if (price == '300000000000000000') {
        //res = await tokenContract.methods.mintAltoCap(quant).send(sendTransactionData);
      //} else {
        res = await tokenContract.methods.mintAlto(quant).send(sendTransactionData);
      //}
      // waiting until transaction will be broadcasted
      let receipt = await web3.eth.getTransactionReceipt(res.transactionHash);
      while (receipt == null) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        receipt = await web3.eth.getTransactionReceipt(res.transactionHash);
      }
      if (quant==1) {
        const tokenId = web3.eth.abi.decodeParameter("uint256", receipt.logs[receipt.logs.length-1].data);
        await showMintedToken(tokenId);
      } else {
        let tokenIds = [];
        for(let i=0;i<quant;i++){
          tokenIds.push(web3.eth.abi.decodeParameter("uint256", receipt.logs[receipt.logs.length-1-2*i].data));
        }
        await showMintedTokens(tokenIds);
      }
      setMinting(false)
    } catch {
      setMinting(false);
    }
  }

  function getMetamaskRequest() {
    return (
      <section className="mint-dc">
        <div className="mint-dc__body">
          <div className="mint-dc__title">Connect your wallet</div>
          <div className="mint-dc__image">
            <img src={`${process.env.PUBLIC_URL}/img/fox-big.svg`} alt="" />
          </div>
          <div className="mint-dc__button button">
            <a
              onClick={onConnectMetamaskClick}
              className="mint-dc__link button__link"
            >
              CONNECT
            </a>
          </div>
        </div>
      </section>
    );
  }

  function toggleConfirmation(event) {
    setConfirmed(!isConfirmed)
  }

  function getPageContent() {
    if (localSaleStatus) {
      if (account && isCorrectNetwork(window.ethereum.chainId) && saleStatus) {
        return (
            <div>
            <section className="mint-c">
              <div className="mint-c__body">
                <div className="mint-c__title">Mint Alto city</div>
                <div className="mint-c__list">
                  <CurrentTokenPrice web3={web3} key={key} />
                  <div className="mint-c__item">
                    <div className="mint-c__item-row">
                      <div className="mint-c__item-price">Number of NFTs to mint</div>
                      <div className="mint-c__item-amount">
                        <span>{quant}    </span>
                        <input {...sliderProps}/>
                      </div>
                    </div>
                  </div>
                  <div className="mint-c__terms">
                    <input type="checkbox" name="checkbox" value="check" id="agree" onChange={toggleConfirmation}/> I accept <NavLink to="/terms">Terms and conditions.</NavLink>
                  </div>
                </div>
                {
                  isMinting
                    ?
                    <div className="loader"></div>
                    :
                    <div
                      className="mint-c__button button"
                      id="popup-mint-link"
                      onClick={isMinting || !isConfirmed || !saleStatus ? () => {} : onClick}
                    >
                      <span className="mint-c__link button__link">{"Mint"}</span>
                    </div>
                }
              </div>
            </section>

            <section className="mint-popup" id="modal">
              <div className="mint-popup__body">
                <div className="mint-popup__content">
                  <div className="mint-popup__exit" onClick={togglePopup}>
                    <span className="mint-popup__exit-link">+</span>
                  </div>
                  <div className="mint-popup__title">
                    This beauty is yours
                  </div>
                  <div className="mint-popup__image">
                    <a href={mintedToken ? `wallet/${mintedToken.token_id}` : ""} alt={mintedToken ? mintedToken.name : ""}>
                      <img src={mintedToken ? `${mintedToken.image}` : ""} alt={mintedToken ? mintedToken.name : ""} />
                      <span>{mintedToken ? mintedToken.name : ""}</span>
                    </a>
                  </div>
                  <div className="mint-popup__subtitle">
                    <a
                      href={mintedToken ? mintedToken.frontend_url : ""}
                      className="mint-popup__subtitle-link"
                    >
                      Check all related physical products
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="mint-popup" id="modals">
              <div className="mint-popup__body">
                <div className="mint-popup__content">
                  <div className="mint-popup__exit" onClick={togglePopups}>
                    <span className="mint-popup__exit-link">+</span>
                  </div>
                  <div className="mint-popup__title">
                    These beauties are yours
                  </div>
                  {mintedTokens.map((mintedTokenn) => (
                    <div className="mint-popup__images">
                      <a href={mintedTokenn ? `wallet/${mintedTokenn.token_id}` : ""} alt={mintedTokenn ? mintedTokenn.name : ""}>
                        <img src={mintedTokenn ? `${mintedTokenn.image}` : ""} alt={mintedTokenn ? mintedTokenn.name : ""} />
                        <span>{mintedTokenn ? mintedTokenn.name : ""}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        )} else {
          return getMetamaskRequest();
      }
    } else {
      return (
        <div>
          <section className="mint-c">
            <div className="mint-c__body">
              <div className="mint-c__title">
                <span>Sold out</span>
                <div className="mint-c__status">Get one on secondary</div>
                <div className="mint-b__status"><a href="https://opensea.io/collection/altocity" title="Alto city on OpenSea">View on OpenSea<img src="/img/os-icon.png"/></a></div>
                <div className="mint-a__status"><a href="https://looksrare.org/collections/0x708da3646cfa5Ab8835672eCB5Ae2F2494b7fA81" title="Alto city on LooksRare">View on LooksRare<img src="/img/looksrare-icon.png"/></a></div>
              </div>
            </div>
          </section>
        </div>
      )
    };
  }

  return <div>{getPageContent()}</div>;
}

export default Mint;
