import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios'

class InfiniteGallery extends Component {

  toPythonBool = (value) => {
    const s = value.toString()
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  state = {
    items: [],
    count: 20,
    start: 0,
    hasMore: true,
    fetchTimerId: null,
    fetchTimerInverval: 300
  };

  componentDidMount = async () => {
    this.fetchItems()
  }

  fetchItems = async() => {
    const { minted, search } = this.props
    const { count, start } = this.state;

    if (search) {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/get_items?search=${search}&minted=${this.toPythonBool(minted)}&offset=${start}`)
      //console.log(res)

      this.setState({
        items: res.data,
        hasMore: true,
        start: start + count
      })
    } else {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/get_items?offset=${start}&minted=${this.toPythonBool(minted)}`)

      this.setState({
        items: res.data,
        start: start + count,
        hasMore: true
      })
    }
  }

  componentDidUpdate(prevProps){
    const { minted, search } = this.props
    if (prevProps.minted + prevProps.search !== minted + search) {
      this.setState({
        start: 0
      })
      clearTimeout(this.state.fetchTimerId)
      const timerId = setTimeout(this.fetchItems, this.state.fetchTimerInverval)
      this.setState({
        fetchTimerId: timerId
      })
    }
  }

  fetchImages = async () => {
    const { count, start } = this.state;
    const { minted, search } = this.props

    if (search) {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/get_items?&search=${search}&minted=${this.toPythonBool(minted)}&offset=${start}`)
      this.setState({
        items: this.state.items.concat(res.data),
        hasMore: res.data.length != 0,
        start: this.state.start + count
      })
    } else {
      const res = await axios.get(`${process.env.REACT_APP_API}/api/get_items?offset=${start}&minted=${this.toPythonBool(minted)}`)

      this.setState({
        items: this.state.items.concat(res.data),
        hasMore: res.data.length != 0,
        start: this.state.start + count
      })
    }

  }

  getAssetCell = (city) => {
    // Display the selected variant with a large image
    // Display the rest of the color variants with color icons

    return (
      <div key={city.selected_variant.token_id} className="gallery-images__item">
        <a href={`gallery/${city.selected_variant.token_id}`}>
          <div className="item-image-wrapper">
            <img src={`${city.selected_variant.image}`} alt={city.selected_variant.name} />
          </div>
        <p>{city.selected_variant.name}</p>
        </a>
        <ul>
          {city.color_variants.sort((a, b) => a.property3val - b.property3val).map((variant) => (
            <li
              key={variant.token_id}
              className={variant.token_id === city.selected_variant.token_id ? 'active' : 'inactive'}
            >
              <a href="#" onClick={(event) => this.handleColorVariantClick(city, variant, event)}>
                <img
                  src={`https://img.altocity.io/static/img/variation_${variant.property3val}.png`}
                  alt=""
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  handleColorVariantClick = (city, variant, event) => {
    event.preventDefault();

    // Switch the selected variant to the clicked color variant
    this.setState({
      items: this.state.items.map(item => item === city ? {...city, selected_variant: variant} : item)
    })
  }

  render() {
    return (
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.fetchImages}
          hasMore={this.state.hasMore}
        >
          <div className="gallery__images gallery-images">
            {this.state.items.map((item) => (
              this.getAssetCell(item)
            ))}
          </div>
        </InfiniteScroll>
    );
  }
}

export default InfiniteGallery;
