import React from 'react';

class WalletSWW extends React.Component {
	render() {
		return (
			<section className="wallet-sww">
				<div className="wallet-sww__body">
					<div className="wallet-sww__title">
						Something went wrong
				</div>
					<div className="wallet-sww__code">
						{this.props.account}
					</div>
					<div className="wallet-sww__list">
						<div className="wallet-sww__item">
							<div className="wallet-sww__subtitle">
								It looks like this item is owned by someone else, try accessing it again from your wallet.
						</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default WalletSWW;