import React, { useState, useEffect } from "react";
import { WalletSWW, WalletDetails } from "../pages";
import ABI from "../abis/contractABI";
import Web3 from "web3";
import { useParams } from "react-router-dom";

const WalletItem = () => {
  const [account, setAccount] = useState(null);
  const [tokenOwner, setTokenOwner] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [network, setNetwork] = useState(null);

  const { id } = useParams();

  const isCorrectNetwork = (currentNetwork) =>
    currentNetwork === '0x1' || currentNetwork === '0x4';

  const connectMetamask = async () => {
    if (!window.ethereum) {
      window.open('https://metamask.io/', '_blank').focus();
      return;
    }

    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      if (!isCorrectNetwork(window.ethereum.chainId)) {
        alert('Please checkout to Mainnet or Rinkeby network');
        return;
      }

      setAccount(accounts[0]);
      setNetwork(window.ethereum.chainId);
    } catch (err) {
      if (err.code === 4001) {
        alert('You need to activate Metamask to continue');
      } else if (err.code === -32002) {
        alert('Check Metamask connection requests');
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!window.ethereum) return;
      const web3Instance = new Web3(window.ethereum);

      const tokenContract = new web3Instance.eth.Contract(
        ABI,
        process.env.REACT_APP_TOKEN_ADDRESS,
      );

      let owner;
      try {
        owner = await tokenContract.methods.ownerOf(id).call();
      } catch (e) {
        owner = null;
      }

      window.ethereum.on('disconnect', () => setAccount(null));
      window.ethereum.on('accountsChanged', (accs) => setAccount(accs[0]));
      window.ethereum.on('chainChanged', (setNetwork) => {
        setNetwork(setNetwork)
      })

      const accounts = await web3Instance.eth.getAccounts();
      setWeb3(web3Instance);
      setAccount(accounts[0]);
      setTokenOwner(owner);
      setNetwork(window.ethereum.chainId);
    };

    init();
  }, [id]);

  if (!account || !isCorrectNetwork(network)) {
    return (
      <section className="mint-dc">
        <div className="mint-dc__body">
          <div className="mint-dc__title">Connect your wallet</div>
          <div className="mint-dc__image">
            <img src={`${process.env.PUBLIC_URL}/img/fox-big.svg`} alt="" />
          </div>
          <div className="mint-dc__button button">
            <button onClick={connectMetamask} className="mint-dc__link button__link">
              CONNECT
            </button>
          </div>
        </div>
      </section>
    );
  }

  const isOwner = tokenOwner ? account.toLowerCase() === tokenOwner.toLowerCase() : false;

  return isOwner ? <WalletDetails tokenId={id}/> : <WalletSWW account={account} />;
};

export default WalletItem;
