import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import ScrollIntoView from "../components/ScrollIntoView";
import RouterGA from "../components/RouterGA";

import Header from "../components/Header"
import Footer from "../components/Footer"


import "../assets/scss/style.scss"

import {
  Home,
  Empty,
  Disclaimer,
  Mint,
  AssetSection,
  Faq,
  Gallery,
  Terms,
  Wallet,
  WalletDetails,
  Auction,
  WalletSWW,
  WalletItem,
  WalletHistory,
  Success
} from "../pages"

// Define a wrapper for AssetSection to pass parameters
function AssetSectionWrapper() {
  let { id } = useParams();

  return <AssetSection id={id} />
}

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <ScrollIntoView>
            <Header/>
            <Routes>
              <Route path="/" exact element={<Home/>} />
              <Route path="/home" exact element={<Home/>} />
              <Route path="/disclaimer" exact element={<Disclaimer/>} />
              <Route path="/mint" exact element={<Mint/>} />
              <Route path="/faq" exact element={<Faq/>} />
              <Route path="/gallery/:id" element={<AssetSectionWrapper/>} />
              <Route path="/gallery" exact element={<Gallery/>} />
              <Route path="/terms" exact element={<Terms/>} />
              <Route path="/wallet/wallet/:id" exact element={<WalletItem/>} />
              <Route path="/wallet/walletHistory" exact element={<WalletHistory/>} />
              <Route path="/wallet" exact element={<Wallet/>} />
              <Route path="/walletSWW" exact element={<WalletSWW/>} />
              <Route path="/wallet/auction" exact element={<Auction/>} />
              <Route path="/success" element={<Success type="success"/>} />
              <Route path="/cancel" element={<Success type="cancel"/>} />
              <Route path="" element={<Empty/>} />
            </Routes>
            <Footer/>
          </ScrollIntoView>
          <RouterGA/>
        </div>
      </Router>
    );
  }
}

export default App;
