import React, { useState, useEffect, useHistory } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import Web3 from 'web3';

export function WalletHistoryPage() {
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transferAmount, setTransferAmount] = useState(0);
  const [discordUsername, setDiscordUsername] = useState(null);
  const [successfulTransferAmount, setSuccessfulTransferAmount] = useState(0);
  const [transferPanelOpen, setTransferPanelOpen] = useState(false);
  const [discordTransferPanelOpen, setDiscordTransferPanelOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userNotFound, setUserNotFound] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [copyText, setCopyText] = useState({text: "", copied: false});

  useEffect(() => {
    const initWeb3 = async () => {
      let web3local;
      if (window.ethereum) {
        web3local = new Web3(window.ethereum);
        setWeb3(web3local);
        const accounts = await web3local.eth.getAccounts();
        setAccount(accounts[0]);
      } else if (window.web3) {
        web3local = new Web3(window.web3.currentProvider);
        setWeb3(web3local);
        const accounts = await web3local.eth.getAccounts();
        setAccount(accounts[0]);
      }
    };

    initWeb3();
  }, []);

  useEffect(() => {
    const getTransactions = async () => {
      if (account) {
        const res = await axios.get(`${process.env.REACT_APP_API}/api/get_wallet_transactions/?wallet=${account}`);
        setTransactions(res.data);
        setLoading(false);
      }
    };
    getTransactions();
  }, [account]);

  const handleInputFocus = () => {
    if(transferAmount === 0) {
      setTransferAmount("");
    }
  }

  const onAltoverseTransferClick = async () => {
    // Making a post request to the Django endpoint
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/altoverse_user_by_wallet/`,
        {
          wallet: account
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (response.data.result) {
        // user exists, render the transfer screen
        setUser(response.data.result);
        setWalletBalance(response.data.walletBalance);
        setTransferPanelOpen(true);
      } else {
        // user does not exist, display error
        alert("You must connect your wallet in Altoverse first");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onTransferToAltoverseClick = async () => {
    try {
      if (transferAmount>walletBalance) {
        setTransferAmount(walletBalance);
      }
      const response = await axios.post(`${process.env.REACT_APP_API}/api/transfer_to_altoverse/`,
        {
          wallet: account,
          amount: transferAmount
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      // Refresh the user coins and wallet balance
      setUser(response.data.result);
      setWalletBalance(response.data.walletBalance);
      setSuccessfulTransferAmount(transferAmount);
    } catch (err) {
      alert(err);
    }
  };

  const onTransferFromAltoverseClick = async () => {
    try {
      if (transferAmount>user.coins) {
        setTransferAmount(user.coins);
      }
      const response = await axios.post(`${process.env.REACT_APP_API}/api/transfer_from_altoverse/`,
        {
          wallet: account,
          amount: transferAmount
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      // Refresh the user coins and wallet balance
      setUser(response.data.result);
      setWalletBalance(response.data.walletBalance);
      setSuccessfulTransferAmount(transferAmount);
    } catch (err) {
      alert(err);
    }
  };

  const onTransferInputChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      setTransferAmount("0");
    } else {
      const maxVal = Math.max(walletBalance, user.coins);
      if (value > maxVal) {
        setTransferAmount(maxVal);
      } else {
        setTransferAmount(value);
      }
    }
  };

  const onDiscordTransferClick = async () => {
    // Making a post request to the Django endpoint
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/discord_user_by_wallet/`,
          {
            wallet: account
          },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (response?.data?.result) {
          // user exists, render the transfer screen
          setUser(response.data.result);
          setDiscordUsername(response.data.username);
          setWalletBalance(response.data.walletBalance);
        }

      setDiscordTransferPanelOpen(true);
    } catch (err) {
      console.error(err);
    }
  };

  const onDiscordUsernameSearch = async () => {
    // Making a post request to the Django endpoint
    try {
        const response = await axios.post(`${process.env.REACT_APP_API}/api/discord_user_by_username/`,
          {
            username: discordUsername,
            wallet: account
          },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );
        if (response?.data?.result) {
          // user exists, render the transfer screen
          setUser(response.data.result);
          setUserNotFound(false);
          setWalletBalance(response.data.walletBalance);
        } else {
          setUserNotFound(true);
          // user does not exist, display error
          alert("Can't find such user in our discord server, try interacting with the bot first or check if you've spelled your account name correctly");
        }
      } catch (error) {
        if (error.response.status === 404) {
          setUserNotFound(true);
          console.error(error);// Set 'userNotFound' to true if a 404 error is returned
        }
      }
  };

  const onTransferToDiscordClick = async () => {
    try {
      if (transferAmount>walletBalance) {
        setTransferAmount(walletBalance);
      }
      const response = await axios.post(`${process.env.REACT_APP_API}/api/transfer_to_discord/`,
        {
          user: user.user,
          wallet: account,
          amount: transferAmount
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      // Refresh the user coins and wallet balance
      setUser(response.data.result);
      setWalletBalance(response.data.walletBalance);
      setSuccessfulTransferAmount(transferAmount);
    } catch (err) {
      alert(err);
    }
  };

  const onTransferFromDiscordClick = async () => {
    try {
      if (transferAmount > user.fractions) {
        setTransferAmount(user.fractions);
      }
      const response = await axios.post(`${process.env.REACT_APP_API}/api/transfer_from_discord/`,
        {
          user: user.user,
          wallet: account,
          amount: transferAmount
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (!response.data.result.wallet && response.data.result.validationcode) {
        // Update the copyText state variable
        setCopyText({text: response.data.result.validationcode, copied: false});
      } else {
        // Refresh the user coins and wallet balance
        setUser(response.data.result);
        setWalletBalance(response.data.walletBalance);
        setSuccessfulTransferAmount(transferAmount);
      }

    } catch (err) {
      alert(err);
    }
  };


  const onDiscordTransferInputChange = (event) => {
    const value = event.target.value;
    if (value === "") {
      setTransferAmount("0");
    } else {
      const maxVal = Math.max(walletBalance, user.fractions);
      if (value > maxVal) {
        setTransferAmount(maxVal);
      } else {
        setTransferAmount(value);
      }
    }
  };

  const renderTransferPanel = () => {
    if (!user || Object.keys(user).length === 0) { // This checks if user is either null, undefined, or an empty object
      return (
        <div className="transfer-panel">
          <h2>Transfer your AltoCoins</h2>
          <p>Please connect your wallet in Altoverse first.</p>
          <button className="cancel-btn" onClick={handleCancelClick}>Cancel</button>
        </div>
      );
    }
  return (
    <div className="transfer-panel">
      <img className="alto-pfp"
           src={user && user.pfpimage ? user.pfpimage.medium : ''}
           alt="Altoverse PFP"
           style={{
             width: '150px',
             height: '150px',
             borderRadius: '50%',
             objectFit: 'cover'
           }}
      />
      <h2>{successfulTransferAmount > 0 ? `Transferred ${successfulTransferAmount}` : user && user.name ? user.name :'Transfer your AltoCoins'}</h2>
      <p>Total Altoverse coins: {user ? user.coins : 0}</p>
      <p>Total wallet coins: {walletBalance}</p>
      <p>Transfer amount <input id="outlined-basic" label="Transfer amount" variant="outlined" type="number" min="0" max={user ? Math.max(walletBalance,user.coins) : walletBalance} value={transferAmount} onChange={onTransferInputChange} onFocus={handleInputFocus}/>
      </p>
      <div className="buttons-container">
        <button className="cancel-btn" onClick={handleCancelClick}>Cancel</button>
        <button className="place-bid-btn" onClick={onTransferToAltoverseClick}>Transfer to Altoverse</button>
        <button className="place-bid-btn" onClick={onTransferFromAltoverseClick}>Transfer from Altoverse</button>
      </div>
    </div>
    );
  };

  const renderDiscordTransferPanel = () => {
    const handleCopyClick = () => {
      navigator.clipboard.writeText(copyText.text);
      setCopyText({...copyText, copied: true});
    };


    if (!user || !user.user || Object.keys(user).length === 0) {
      return (
        <div className="transfer-panel">
          <h2>{userNotFound ? "User not found or no coins on Discord, check username" : "Please enter your Discord username"}</h2>
          <p>Discord username <input id="outlined-basic" label="Discord username" variant="outlined" maxLength={50} value={discordUsername} onBlur={onDiscordUsernameSearch} onChange={(event) => setDiscordUsername(event.target.value)}/></p>
          <div className="buttons-container">
            <button className="cancel-btn" onClick={handleCancelClick}>Cancel</button>
            <button className="place-bid-btn" onClick={onDiscordUsernameSearch}>Find user</button>
          </div>
        </div>
      );
    }

    return (
      <div className="transfer-panel">
        <img className="discord-pfp"
             src={`https://cdn.discordapp.com/avatars/${String(user.user)}/${String(user.icon)}.png`}
             alt="Discord PFP"
             style={{
               width: '150px',
               height: '150px',
               borderRadius: '50%',
               objectFit: 'cover'
             }}
        />
        {copyText.text && !user.wallet ? (
          <div>
            <h2>{"Please use   /verify "}
              <a className="copy-container" onClick={handleCopyClick} href="#">
                {copyText.text}
                <button className={`copy-btn ${copyText.copied ? 'copied' : ''}`} onClick={handleCopyClick}>{copyText.copied ? 'Copied' : 'Copy'}</button>
              </a>
              in discord to verify your ownership
            </h2>
          </div>
        ) : (
          <h2>{successfulTransferAmount > 0 ? `Transferred ${successfulTransferAmount}` : user && user.username ? user.username :'Transfer your Discord Coins'}</h2>
        )}
        <p>Total Discord coins: {user ? user.fractions : 0}</p>
        <p>Total wallet coins: {walletBalance}</p>
        <p>Transfer amount <input id="outlined-basic" label="Transfer amount" variant="outlined" type="number" min="0" max={user ? Math.max(walletBalance,user.fractions) : walletBalance} value={transferAmount} onChange={onDiscordTransferInputChange} onFocus={handleInputFocus}/>
        </p>
        <div className="buttons-container">
          <button className="cancel-btn" onClick={handleCancelClick}>Cancel</button>
          <button className="place-bid-btn" onClick={onTransferToDiscordClick}>Transfer to Discord</button>
          <button className="place-bid-btn" onClick={onTransferFromDiscordClick}>Transfer from Discord</button>
        </div>
      </div>
    );
  };

  const handleCancelClick = () => {
    setSuccessfulTransferAmount(0);
    setTransferPanelOpen(false);
    setDiscordTransferPanelOpen(false);
  };

  const getMetamaskRequest = () => {
    return (
      <section className="mint-dc">
        <div className="mint-dc__body">
          <div className="mint-dc__title">Connect your wallet</div>
          <div className="mint-dc__image">
            <img src={`${process.env.PUBLIC_URL}/img/fox-big.svg`} alt="" />
          </div>
          <div className="mint-dc__button button">
            <a
              onClick={onConnectMetamaskClick}
              className="mint-dc__link button__link"
            >
              CONNECT
            </a>
          </div>
        </div>
      </section>
    );
  };

  const onConnectMetamaskClick = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(res[0])
      } catch (err) {
        if (err.code === 4001) {
          alert("You need to get Metamask to continue");
        } else if (err.code === -32002) {
          alert("Check metamask connection requests");
        } else {
          console.log(err);
        }
      }
    } else {
      window.open("https://metamask.io/", "_blank").focus();
    }
  };

  const renderTransactions = () => {
    if (loading) {
      return <div>Loading...</div>
    }

    return (
      <table>
        <thead>
        <tr>
          <th>Type</th>
          <th>Amount</th>
          <th>Date</th>
        </tr>
        </thead>
        <tbody>
        {transactions.map(transaction => (
          <tr className="current" key={transaction.history_id} >
            <td>{transaction.type}</td>
            <td>{transaction.altocoins}</td>
            <td>{new Date(transaction.transaction_date).toLocaleString()}</td>
          </tr>
        ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="wallet-history">
      {account ? (
        <>
          <div className="wallet-history__title">
            <div className="button-group">
              <button onClick={onAltoverseTransferClick} className="transfer-button">Altoverse Transfer</button>
              <button onClick={onDiscordTransferClick} className="discord-transfer-button">Discord Transfer</button>
            </div>
          </div>

          <div className="transactions-table">
            {renderTransactions()}
          </div>

          {transferPanelOpen && renderTransferPanel()}

          {discordTransferPanelOpen && renderDiscordTransferPanel()}
        </>
      ) : getMetamaskRequest()}
    </div>
  );
}

export default WalletHistoryPage;
