import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';

import InfiniteGallery from "../components/InfiniteGallery";

export function Gallery() {
  const urlParams = new URLSearchParams(window.location.search);

  const [search, setSearch] = useState(urlParams.get('search') || '')
  const [minted, setMinted] = useState(urlParams.get('minted') === 'true')
  const [inputValue, setInputValue] = useState(search);

  const debouncedSetSearch = debounce(setSearch, 500);

  const toggleCheckbox = () => {
    const updatedMinted = !minted;
    setMinted(updatedMinted)
    window.history.replaceState(null, null, `?${inputValue ? `search=${encodeURIComponent(inputValue)}&` : ""}minted=${updatedMinted}`);
  }

  const onSearchTextChange = (event) => {
    setInputValue(event.target.value);
    debouncedSetSearch(event.target.value);
  }

  useEffect(() => {
    window.history.replaceState(null, null, `?${inputValue ? `search=${encodeURIComponent(inputValue)}&` : ""}minted=${minted}`);
  }, [search, minted, inputValue]);

  return (
    <section className="gallery">
      <div className="gallery__body">
        <div className="gallery__search gallery-search">
          <div className="gallery-search__column1 gallery-search__column">
            <input type="text" placeholder="Search for cities" onChange={onSearchTextChange} value={inputValue} />
          </div>
          <div className="gallery-search__column2">
            <div className="gallery-search__item">
              To be discovered
            </div>
            <div className="gallery-search__item">
              <div className="gallery-search__toggles">
                <input
                  type="checkbox"
                  name="checkbox1"
                  id="checkbox1"
                  checked={minted}
                  className="gallery-search__ios-toggle"
                  onChange={toggleCheckbox}
                />
                <label
                  htmlFor="checkbox1"
                  className="gallery-search__checkbox-label"
                  data-off="off"
                  data-on="on"
                ></label>
              </div>
            </div>
            <div className="gallery-search__item">
              Minted or swapped out
            </div>
          </div>
        </div>
        <InfiniteGallery minted={minted} search={encodeURIComponent(search)}/>
      </div>
    </section>
  );
}

export default Gallery;
