import React from 'react';
import { Link } from 'react-router';

class Empty extends React.Component {
    render() {
      return (
        <section className="home">
        <div className="home__body">
          <h1 className="home__title">
            404 Not Found
          </h1>
        </div>
        </section>
      );
    }
}

export default Empty;