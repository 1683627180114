import React from 'react';
import { Link } from 'react-router';


class Collection extends React.Component {
    render() {
      return (
        <section className="home">
          <div className="home__body">
              <iframe id="hhome" src={`https://img.altocity.io/static/hy/collection/collection.html`}  scrolling="yes"></iframe>
          </div>
        </section>
      );
    }
}

export default Collection;
