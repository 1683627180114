import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function RouterGA() {
  const location = useLocation();

  useEffect(() => {
    const gtag = window.gtag;

    if (typeof gtag === 'function') {
      gtag('config', 'G-YNTQN2MP5D', {
        'page_title': document.title,
        'page_location': window.location.href,
        'page_path': location.pathname
      });
    }
  }, [location]);

  return null;
}

export default RouterGA;
