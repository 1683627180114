import React from "react";
import axios from "axios";

class WalletDetails extends React.Component {
  state = {
    asset: {
      total_due: 0,
      total_latest: 0,
      due_res: [],
      latest_res: [],
    },
    image: "",
    name: "",
    frontend_url: "",
    token_id: "",
    bidPanelOpen: false,
    swapPanelOpen: false,
    totalBid: null,
    userBalance: null,
    wallet: null,
    newBid: 0,
    city: "",
    country: "",
    color: "",
    state: "",
    currentBid: null,
    availableSwaps: 0,
    availableSwapsSameCountry: 0,
    availableSwapsSameCity: 0,
    apiError: null,
    swapOption: 0,
    swapCost: null
  };

  reloadData = async () => {
    const id = this.props.tokenId;
    //const res = await axios.get(
    //  `${process.env.REACT_APP_API}/api/get_asset?token_id=${id}&address=${window.ethereum.selectedAddress}`
    //);
    const itemData = await axios.get(
      `${process.env.REACT_APP_API}/api/get_item/${id}`
    );

    const emptyAsset = {
      total_due: 0,
      total_latest: 0,
      due_res: [],
      latest_res: [],
    }

    this.setState({
      asset: emptyAsset,
      image: itemData.data.image,
      name: itemData.data.name,
      frontend_url: itemData.data.frontend_url,
      token_id: itemData.data.token_id,
    });

    const bidRes = await axios.get(
      `${process.env.REACT_APP_API}/api/get_bid_data/${id}`
    );

    this.setState({
      city: bidRes.data.city,
      country: bidRes.data.country,
      state: bidRes.data.state,
      color: bidRes.data.color,
      totalBid: bidRes.data.totalBid,
      currentBid: bidRes.data.currentBid,
      userBalance: bidRes.data.userBalance,
      wallet: bidRes.data.wallet
    });

    const royRes = await axios.get(
      `${process.env.REACT_APP_API}/api/get_royalties_data/${id}`
    );

    this.setState({asset:{
      total_due: royRes.data.total_due,
      total_latest: royRes.data.last_due,
      due_res: royRes.data.due_res,
      latest_res: royRes.data.latest_res}
    });

    try {
      const swapRes = await axios.get(`${process.env.REACT_APP_API}/api/get_swap_data/${id}`);

      this.setState({
        availableSwaps: swapRes.data.availableSwaps,
        availableSwapsSameCountry: swapRes.data.availableSwapsSameCountry,
        availableSwapsSameCity: swapRes.data.availableSwapsSameCity,
      });
    } catch (error) {
      this.setState({ apiError: error.response.data }); // assuming the error message is in error.response.data
    }
  };

  componentDidMount() {
    this.reloadData();
  }


  toggleBidPanel = () => {
    this.setState(prevState => ({ bidPanelOpen: !prevState.bidPanelOpen }));
  }

  handleBidChange = (event) => {
    this.setState({ newBid: event.target.value });
  }

  handlePlaceBid = () => {
    const { newBid, userBalance, city, country, state, wallet } = this.state;
    if (newBid > userBalance || newBid < 1) {
      alert("Your bid can't exceed your balance");
    } else {
      axios.post(`${process.env.REACT_APP_API}/api/place_bid/`, {
        wallet: wallet,
        bet: newBid,
        city: city,
        country: country,
        state: state
      })
        .then(response => {
          if(response.data.status === 'success'){
            alert(response.data.message);
            this.reloadData();
            this.toggleBidPanel();
          }
          else {
            alert(response.data.message);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  toggleNeighbourTable = (e) => {
    const item = e.currentTarget;
    item.querySelector(".asset-content__dropdown").classList.toggle("up");
    item.nextSibling.classList.toggle("show");
  };

  cancelSwap = () => {
    this.setState(prevState => ({ swapPanelOpen: !prevState.swapPanelOpen }));
  }

  handleSwap = async (type) => {
    let swapCost;

    if (type === 'sameCountry') {
      swapCost = 600;
    } else if (type === 'sameCity') {
      swapCost = 1000;
    } else {
      swapCost = 300;
    }

    this.setState({ swapOption: type, swapCost }, async () => {
      try {
        // call your swap API here with id and type, handle the response
      } catch (error) {
        this.setState({ apiError: error.response.data });
      }
    });
    this.setState(prevState => ({ swapPanelOpen: !prevState.swapPanelOpen }));
  };

  confirmSwap = async () => {
    const { city, country, color, state, swapOption, wallet } = this.state;
    const confirmSwapRes = await axios.post(
      `${process.env.REACT_APP_API}/api/confirm_swap/`,
      {
        token: this.props.tokenId,
        city: city,
        country: country,
        state: state,
        color: color,
        wallet: wallet,
        swapOption: swapOption
      }
    );
    if (confirmSwapRes.data.errors) {
      // Handle errors here
    } else {
      this.reloadData();
      this.setState(prevState => ({ swapPanelOpen: !prevState.swapPanelOpen }));
    }
  };

  render() {
    const { bidPanelOpen, swapPanelOpen, city, country, color, currentBid, totalBid, userBalance, newBid, availableSwaps, availableSwapsSameCountry, availableSwapsSameCity, apiError , swapOption , swapCost } = this.state;
    return (
      <section className="wallet-asset">
        <div className="wallet-asset__body">
          <div className="wallet-asset__top resp">
            <div className="wallet-asset__title">{this.state.name}</div>
          </div>

          <div className="wallet-asset__image">
            <img src={`${this.state.image}`} alt="" />
            <div className="wallet-asset__subtitle">
              <a href={`${this.state.frontend_url}`}>
                Check related physical products
              </a>
            </div>
            <div className="wallet-asset__subtitle">
              <a href={`${process.env.REACT_APP_OPENSEA_COLLECTION}/${this.state.token_id}`} className="asset-sub__list-link">
                View this item on OpenSea
              </a>
            </div>
          </div>

          <div className="wallet-asset__block">
            <div className="wallet-asset__top desc">
              <div className="wallet-asset__title">{this.state.name}</div>
            </div>

            <div className="wallet-detail-buttons">
              <div className="buttons-container">
              <button className="AuctionBid" onClick={() => this.toggleBidPanel()}>Next City Auction</button>
              {!apiError ? (
                <>
                  {availableSwaps > 0 && (
                    <button className="swap-btn" onClick={() => this.handleSwap('random')}>Swap Random - 300 Alto Coins</button>
                  )}
                  {availableSwapsSameCountry > 0 && (
                    <button className="swap-btn" onClick={() => this.handleSwap('sameCountry')}>Swap Same Country - 600 Alto Coins</button>
                  )}
                  {availableSwapsSameCity > 0 && (
                    <button className="swap-btn" onClick={() => this.handleSwap('sameCity')}>Swap Same City - 1000 Alto Coins</button>
                  )}
                </>
              ) : (
                <div className="api-error">{apiError}</div>
              )}
              </div>
            </div>
            <div className="wallet-asset__details">Latest bonus points details</div>

            <div className="wallet-asset__total asset-total">
              <div className="asset-total__title-row">
                <div className="asset-total__due">Total due this month</div>
                <div className="asset-total__amount">
                  {this.state.asset.total_due}
                </div>
              </div>

              <div className="asset-total__content asset-content">
                {this.state.asset.due_res.map((value, index) => {
                  return (
                    <div className="asset-content__item" key={index}>
                      <div
                        className="asset-content__row"
                        onClick={this.toggleNeighbourTable}
                      >
                        <div className="asset-content__title">
                          {value.product}
													{""}
                          <span className="asset-content__dropdown"></span>
                        </div>
                        <div className="asset-content__amount">
                          {value.total}
                        </div>
                      </div>
                      <table className="asset-content__table asset-table">
                        <thead>
                          <tr>
                            <td>Revenue stream</td>
                            <td>Total</td>
                            <td>Your share</td>
                          </tr>
                        </thead>
                        <tbody>
                          {value.details.map((e, i) => {
                            return (<tr key={i}>
                              <td>{e.stream}</td>
                              <td>{e.total}</td>
                              <td>{e.share} </td>
                            </tr>)
                          })}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
							</div>
            </div>
            <div className="wallet-asset__sub">
              All your bonus Alto Coins will be automatically transferred to your account at the end of the month.
            </div>

            <div className="wallet-asset__total asset-total">
              <div className="asset-total__title-row">
                <div className="asset-total__due">Last month's points</div>
                <div className="asset-total__amount">
                  {this.state.asset.total_latest}
                </div>
              </div>

              <div className="asset-total__content asset-content">
              {this.state.asset.latest_res.map((value, index) => {
                  //console.log(value);
                  return (
                    <div className="asset-content__item" key={index}>
                      <div
                        className="asset-content__row"
                        onClick={this.toggleNeighbourTable}
                      >
                        <div className="asset-content__title">
                          {value.product}
													{""}
                          <span className="asset-content__dropdown"></span>
                        </div>
                        <div className="asset-content__amount">
                          {value.total}
                        </div>
                      </div>
                      <table className="asset-content__table asset-table">
                        <thead>
                          <tr>
                            <td>Revenue stream</td>
                            <td>Total</td>
                            <td>Your share</td>
                          </tr>
                        </thead>
                        <tbody>
                          {value.details.map((e, i) => {
                            return (<tr key={i}>
                              <td>{e.stream}</td>
                              <td>{e.total}</td>
                              <td>{e.share} </td>
                            </tr>)
                          })}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {bidPanelOpen && city && (
            <div className="auction_bid">
              <h2>Place Your Bid</h2>
              <p>City: {city}</p>
              <p>Country: {country}</p>
              <p>Total Bid: {totalBid}</p>
              <p>Current Bid: {currentBid}</p>
              <p>Your Balance: {userBalance}</p>
              <input type="number" min="0" max={userBalance} value={newBid} onChange={this.handleBidChange}/>
              <div className="buttons-container">
                <button className="cancel-btn" onClick={this.toggleBidPanel}>Cancel</button>
                <button className="place-bid-btn" onClick={this.handlePlaceBid}>Place Bid</button>
              </div>
              <p className="disclaimer">Disclaimer: You cannot cancel your bids; if your bid does not play, you will get a full refund.</p>
            </div>
          )}
          {swapPanelOpen && availableSwaps && (
            <div className="swap_wrapper">
              <h2>You're about to burn it out from the collection</h2>
              <p>City: {city}</p>
              <p>Country: {country}</p>
              <p>Color: {color}</p>
              <p>Swap option: {swapOption}</p>
              <p>Swap cost: {swapCost}</p>
              <p>Your Balance: {userBalance}</p>
              <div className="buttons-container">
                <button className="cancel-btn" onClick={this.cancelSwap}>Cancel</button>
                <button className="place-bid-btn" onClick={this.confirmSwap}>Confirm</button>
              </div>
              <p className="disclaimer">Disclaimer: This NFT will be swapped out forever, it can't be undone.</p>
            </div>
          )}
        </div>
      </section>
    );
  }
}
export default WalletDetails;
